import React from 'react';

interface NpsScoreProps {
  score: number; // Erwartet eine Zahl zwischen 0 und 10
}

const NpsScale: React.FC<NpsScoreProps> = ({ score }) => {
  // farbcode bestimmen 
  const getColorForSection = (index: number): string => {
    if (index <= 6) return 'bg-red-500';
    if (index <= 8) return 'bg-yellow-300';
    return 'bg-green-500';
  };

  // typ bestimmen 
  const getNPSTypeForSection = (index: number): string => {
    if (index <= 6) return 'Kritiker';
    if (index <= 8) return 'Passive';
    return 'Promotoren';
  };

  // Generiere die Abschnitte
  const sections = Array.from({ length: 11 }, (_, index) => (
    <div
      key={index}
      className={`flex-1 ${getColorForSection(index)} ${index === score ? 'h-5 border-2 border-black' : 'h-2'} mt-auto`}
    />
  ));

  return (
    <>
    <div className='flex'>
    <div className='text-lg leading-none text-gray-900 dark:text-white'>
        NPS&nbsp;
    </div>
    <div className='text-lg font-bold leading-none text-gray-900 dark:text-white'>
        {score}
    </div>
    </div>
    <div className="flex w-full h-6 items-end">
        {sections}
    </div>
    <div className='mt-1 text-m leading-none text-gray-900 dark:text-white'>
       {getNPSTypeForSection(score)}
    </div>
    </>
  );
};

export default NpsScale;

import React from 'react';
import { Modal, Button } from 'flowbite-react';


interface PopupNachfassProps {
  feedbackid: string;
}




const PopupNachfass: React.FC<PopupNachfassProps> = ({ feedbackid }) => {

  const handleNachfass = () => {
    const token = localStorage.getItem('token'); // Token aus dem LocalStorage holen
      if (!token) {
        alert('Token nicht gefunden. Bitte erneut anmelden.');
        return;
      }

    fetch(`${process.env.REACT_APP_API_BASE_URL}/movekpifeedback`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + token 
      },
      body: JSON.stringify(
        {
          sourceTable: 'edited_kpi',
          targetTable: 'approved_kpi', 
          kpi_feedback_id: feedbackid
        }
      )
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Netzwerkantwort war nicht ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Erfolg:', data);
      window.location.reload();
    })
    .catch((error) => {
      console.error('Fehler:', error);
      alert('Fehler beim Verschieben des Feedbacks');
    });
  }

return (
    <>
        <Modal.Header>Archivieren</Modal.Header>
        <Modal.Body>
          <div>
            <p className="text-base font-bold text-gray-900 dark:text-white mb-5">
            Sind Sie sicher, dass Sie den Vorgang archivieren möchten?
            </p>
            <Button className="border-2 dark:text-white" color="gray dark:text-white" onClick={handleNachfass}>
                Ja, archivieren
            </Button>

            <p className="text-sm text-gray-900 dark:text-white">
            Archivierte Vorgänge finden Sie im Reiter "Archiv"
            </p>
          </div>
        </Modal.Body>
    </>
  );
};

export default PopupNachfass;

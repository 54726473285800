import type { FC } from "react";
import React, { useEffect } from "react";
import VKPIChatModalUser from "./v_kpi_chat_user.tsx";
import { useState } from 'react';
import { Button, Modal } from 'flowbite-react';
import KPIComponent from "./v_kpi_kpi.tsx";

// KPI Feedback Component User 
// Nimmt Feedback Data entgegen und stellt diese dar

// Infos zur Darstellung 
interface FeedbackData {
    email_supervisor: string;
    email_user: string;
    firstname_user: string; 
    lastname_user: string; 
    kpi_date: string; 
    kpi_feedback_id: string;
    kpi_text: string;
    kpi_time: string; 
    kpi_value: number; 
    custom1: string; 
    custom2: string;
    custom3: string;
    custom4: string;
    custom5: string;
    custom6: string;
    custom7: string;
    custom8: string;
    custom9: string;
    custom10: string;
    custom11: string;
    custom12: string;
    custom13: string;
    custom14: string;
    custom15: string;
    custom16: string;
    custom17: string;
    custom18: string;
    custom19: string;
    custom20: string;
    custom21: string;
    custom22: string;
    custom23: string;
    custom24: string;
    custom25: string;
    custom26: string;
    custom27: string;
    custom28: string;
    custom29: string;
    custom30: string;
    custom31: string;
    custom32: string;
    custom33: string;
    custom34: string;
    custom35: string;
    custom36: string;
    custom37: string;
    custom38: string;
    custom39: string;
    custom40: string;
    mandant: string;
    survey1: string;
    survey2: string;
    survey3: string;
    survey4: string;
    survey5: string;
    survey6: string;
    survey7: string;
    survey8: string;
    survey9: string;
    survey10: string;
    survey11: string;
    survey12: string;
    survey13: string;
    survey14: string;
    survey15: string;
    survey16: string;
    survey17: string;
    survey18: string;
    survey19: string;
    survey20: string;
    survey21: string;
    survey22: string;
    survey23: string;
    survey24: string;
    survey25: string;
    survey26: string;
    survey27: string;
    survey28: string;
    survey29: string;
    survey30: string;
    survey31: string;
    v_kpi_label1: string;
    v_kpi_type1: string;
    v_kpi_value1: string;
    v_kpi_label2: string;
    v_kpi_type2: string;
    v_kpi_value2: string;
    v_kpi_label3: string;
    v_kpi_type3: string;
    v_kpi_value3: string;
    v_kpi_label4: string;
    v_kpi_type4: string;
    v_kpi_value4: string;
    v_kpi_label5: string;
    v_kpi_type5: string;
    v_kpi_value5: number;
    v_kpi_label6: string;
    v_kpi_type6: string;
    v_kpi_value6: string;
    v_kpi_desc_label_1: string;
    v_kpi_desc_val_1: string;
    v_kpi_desc_label_2: string;
    v_kpi_desc_val_2: string;
    v_kpi_desc_label_3: string;
    v_kpi_desc_val_3: string;
    v_kpi_desc_label_4: string;
    v_kpi_desc_val_4: string;
    v_kpi_desc_label_5: string;
    v_kpi_desc_val_5: string;
    v_kpi_desc_label_6: string;
    v_kpi_desc_val_6: string;
    v_kpi_desc_label_7: string;
    v_kpi_desc_val_7: string;
    v_kpi_desc_label_8: string;
    v_kpi_desc_val_8: string;
    v_kpi_desc_label_9: string;
    v_kpi_desc_val_9: string;
    v_kpi_desc_label_10: string;
    v_kpi_desc_val_10: string;
}

interface KPIUserProps {
    feedbackData: FeedbackData;
    sourcetable: string; 
    targettable: string;
}

const VKPIUser: FC<KPIUserProps> = ({ feedbackData, sourcetable, targettable }) => {
    const [appcolorone, setAppColorOne] = useState<string | null>(null);
    useEffect(() => {
          
          setAppColorOne(localStorage.getItem('appcolorone')); 
        }, []);

    const [openModal, setOpenModal] = useState(false);
    return (
      <div className="relative mb-4 h-full rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6 w-full border-2">
            <Modal size="xl" show={openModal} onClose={() => setOpenModal(false)}>
                    <VKPIChatModalUser sourcetable={sourcetable} targettable={targettable} feedbackid={feedbackData.kpi_feedback_id} firstname={feedbackData.custom18} lastname={feedbackData.custom17} email_user={feedbackData.email_user}/>           
                <Modal.Footer className="relative">
                    <div className="mt-2 mb-2"></div>
                    <Button className="absolute right-5 border-2 dark:text-white" color="gray dark:text-white" onClick={() => setOpenModal(false)}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal> 
            <div className="absolute top-0 right-0 p-2">            
            <div className="flex items-center justify-between">
                <div className="shrink-0">
                <button
                className={'border-2 p-2 dark:text-white inline-flex items-center rounded-xl text-xs font-medium uppercase text-white text-primary-700 bg-'+ appcolorone+ ' hover:bg-blue-100 dark:text-primary-500 dark:hover:bg-gray-700 sm:text-sm'}
                onClick={() =>setOpenModal(true)}
                >
                Kommentieren
                <svg
                    className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                    />
                </svg>
                </button>
                </div>
            </div>
            </div>
        <div className="flow-root">
          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
            <li>
            <div>
            <KPIComponent
                title={feedbackData.v_kpi_label1}
                value={feedbackData.v_kpi_value1}
                difference={feedbackData.v_kpi_value5}
                subtitle="Delta"
                additionalKPIs={[
                { title: feedbackData.v_kpi_desc_label_4, value: feedbackData.v_kpi_desc_val_4 },
                { title: feedbackData.v_kpi_label2, value: feedbackData.v_kpi_value2 },
                { title: feedbackData.v_kpi_label3, value: feedbackData.v_kpi_value3 },
                { title: feedbackData.v_kpi_label4, value: feedbackData.v_kpi_value4 }
                ]}
            />          
            </div>
            </li>
            <li className="mt-3">
            <p className="text-md font-bold text-gray-500 dark:text-gray-400 mb-1 mt-1">{feedbackData.v_kpi_desc_label_1}</p>
            <div className="mb-4 flex items-center justify-between">
                <p className="text-base text-gray-900 dark:text-white">
                    {feedbackData.v_kpi_desc_val_1}
                </p>
            </div>
            </li>
            <li className="mt-3">
            <p className="text-md font-bold text-gray-500 dark:text-gray-400 mb-1 mt-1">{feedbackData.v_kpi_desc_label_2}</p>
            <div className="mb-4 flex items-center justify-between">
                <p className="text-base text-gray-900 dark:text-white">
                    {feedbackData.v_kpi_desc_val_2}
                </p>
            </div>
            </li>
            <li className="mt-3">
            <p className="text-md font-bold text-gray-500 dark:text-gray-400 mb-1 mt-1">{feedbackData.v_kpi_desc_label_3}</p>
            <div className="mb-4 flex items-center justify-between">
                <p className="text-base text-gray-900 dark:text-white">
                    {feedbackData.v_kpi_desc_val_3}
                </p>
            </div>
            </li>
            <li className="mt-3">
            <p className="text-md font-bold text-gray-500 dark:text-gray-400 mb-1 mt-1">{feedbackData.v_kpi_desc_label_5}</p>
            <div className="mb-4 flex items-center justify-between">
                <p className="text-base text-gray-900 dark:text-white">
                    {feedbackData.v_kpi_desc_val_5}
                </p>
            </div>
            </li>
            <li className="mt-3">
            <p className="text-md font-bold text-gray-500 dark:text-gray-400 mb-1 mt-1">{feedbackData.v_kpi_desc_label_6}</p>
            <div className="mb-4 flex items-center justify-between">
                <p className="text-base text-gray-900 dark:text-white">
                    {feedbackData.v_kpi_desc_val_6}
                </p>
            </div>
            </li>            
          </ul>
        </div>
      </div>
    );
  };


  export default VKPIUser;

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext.tsx'; // Stelle sicher, dass der Pfad zu deinem AuthContext stimmt

const ProtectedRoute: React.FC = () => {
  const { isAuthenticated } = useAuth(); // Nutze deinen Authentifizierungskontext
//<Outlet />
  const mandant = localStorage.getItem('mandant')
  const navpath = `/${mandant}/login`
  return isAuthenticated ? <Outlet /> : <Navigate to={navpath} replace />;
};

export default ProtectedRoute;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Label, TextInput } from "flowbite-react";
import type { FC } from "react";
import muuuhlogo from "../assets/muuuh_logo.png";
import React, { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext.tsx'; 
import { jwtDecode } from "jwt-decode";

// TODO pw ändern Endpunkt aufrufen

const ChangePWPage: FC = function () {
   // Logo dynamisch je Mandant laden 
   let location = useLocation();
   let pathSegments = location.pathname.split('/')
   let mandant_seg = pathSegments[1]
   let logopath = process.env.PUBLIC_URL +'/'+ mandant_seg + '.svg'
  
  interface DecodedToken {
    username: string;
    role: 'user' | 'supervisor';
  }
  const [passwordTwo, setPasswordTwo] = useState<string>('');
  const [new_password, setNewPassword] = useState<string>('');
  const [error, setError] = useState<string>(''); // Zustand für die Fehlermeldung
  const auth = useAuth();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  let email_user = ''
  if (token) {
    const decoded = jwtDecode(token) as DecodedToken;
    email_user = decoded.username
  }; 

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(''); // Setze den Fehlerzustand zurück, bevor ein neuer Login-Versuch unternommen wird

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/changepassword`;

    try {
      console.log(apiUrl)
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ email_user, new_password }),
      });

      if (!response.ok) {
       
        setError('Passwortänderung fehlgeschlagen. Bitte überprüfe deine Eingaben.');
        return;
      }

      const data = await response.json();
      auth.logout(); 
    } catch (error) {
      console.error('Ein Fehler ist aufgetreten:', error);
      setError('Ein Fehler ist aufgetreten. Bitte versuche es später erneut.');
    }
  };
 
  return (
    <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">
      <div className="flex items-center gap-x-1">
            <img
            alt="Muuuh Group Logo"
            src={muuuhlogo}
            className="w-1/4"
            />
        </div>
      <Card
        className="w-full md:max-w-screen-sm [&>img]:hidden md:[&>img]:w-96 md:[&>img]:p-0 md:[&>*]:w-full md:[&>*]:p-16 lg:[&>img]:block"
      >
        <img
            alt="Logo"
            src={logopath}
            className="w-1/3"
            />
        <p className="mb-3 text-xl font-bold dark:text-white">
          Passwort ändern
        </p>
        <form onSubmit={handleLogin}>
        <div className="mb-6 flex flex-col gap-y-3">
            <Label htmlFor="password">Neues Passwort</Label>
            <TextInput
              id="password"
              name="password"
              placeholder="••••••••"
              type="password"
              value={new_password}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="mb-6 flex flex-col gap-y-3">
            <Label htmlFor="password">Neues Passwort bestätigen</Label>
            <TextInput
              id="password"
              name="password"
              placeholder="••••••••"
              type="password"
              value={passwordTwo}
              onChange={(e) => setPasswordTwo(e.target.value)}
            />
          </div>
          <div className="mb-6">
          <Button
            type="submit"
            disabled={new_password !== passwordTwo || new_password === '' || passwordTwo === ''} // Button wird deaktiviert, wenn die Bedingungen erfüllt sind
            className={`w-full lg:w-auto ${new_password !== passwordTwo || new_password === '' || passwordTwo === '' ? 'bg-gray-500' : 'bg-spkrot hover:bg-gray-500'}`}
          >
            Passwort ändern
          </Button>

          </div>
        </form>
      </Card>
    </div>
  );
};

export default ChangePWPage;
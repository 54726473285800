import React, { useEffect, useState } from 'react';
import type { FC } from "react";
import { DarkThemeToggle, Navbar, Avatar, Dropdown } from "flowbite-react";
import { jwtDecode } from "jwt-decode";
import { useAuth } from '../AuthContext.tsx';
import { useLocation } from 'react-router-dom';

const KPINavbar: FC = function () {
  const [appname, setAppName] = useState<string | null>(null);
  const [appcolorone, setAppColorOne] = useState<string | null>(null);
  
  interface DecodedToken {
    username: string;
    role: 'user' | 'supervisor';
  }
  const { logout } = useAuth(); 
  const [username, setUsername] = useState(''); 
  useEffect(() => {
    // Decodiere den JWT Token
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token) as DecodedToken;
      setUsername(decoded.username);
    
      }
      setAppName(localStorage.getItem('appname')); 
      setAppColorOne(localStorage.getItem('appcolorone')); 
    }, []);

  const handleLogout = () => {
    logout()
  }
  const mandant_local = localStorage.getItem('mandant')
  const location = useLocation(); 
  const isActive = (path: string) => location.pathname === path;

  let logopath = process.env.PUBLIC_URL +'/'+ mandant_local + '.svg'


  return (
    // Verwende Tailwind CSS Utility-Klassen, um die Navbar sticky zu machen
    <div className="sticky top-0 z-50">
      <Navbar fluid>
        <div className="w-full p-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Navbar.Brand href={'/'+ mandant_local +'/dashboard_neu'} >
                <img alt="Logo" src={logopath} className="mr-5 h-12" />
                <span className="self-center whitespace-nowrap text-2xl font-semibold dark:text-white">
                  {appname}
                </span>
              </Navbar.Brand>
              <div className="ml-10">
                <Navbar.Collapse>
                    <Navbar.Link href={'/'+ mandant_local +'/dashboard_neu'} className={isActive('/' + mandant_local +'/dashboard_neu') ? 'text-'+appcolorone : undefined} >
                    Neu
                    </Navbar.Link>
                    <Navbar.Link href={'/'+ mandant_local +'/dashboard_wiedervorlage'} className={isActive('/'+ mandant_local +'/dashboard_wiedervorlage') ? 'text-'+appcolorone : undefined} >
                    Korrigiert
                    </Navbar.Link>
                    <Navbar.Link href={'/'+ mandant_local +'/dashboard_nachkontakt'} className={isActive('/'+ mandant_local +'/dashboard_nachkontakt') ? 'text-'+appcolorone : undefined}>
                      Kommentiert</Navbar.Link>
                    <Navbar.Link href={'/'+ mandant_local + '/dashboard_archiv'} className={isActive('/'+ mandant_local + '/dashboard_archiv') ? 'text-'+appcolorone : undefined}>Archiv</Navbar.Link>
                </Navbar.Collapse>
             </div>
            </div>
            <div className="flex items-center gap-3">
              <DarkThemeToggle />
              <div className="flex md:order-2">
                <Dropdown
                arrowIcon={false}
                inline
                label={
                    <Avatar alt="User settings"  />
                }
                >
                <Dropdown.Header>
                    <span className="block truncate text-sm font-medium">{username}</span>
                </Dropdown.Header>
                <Dropdown.Item href={'/'+ mandant_local + '/changepw'}>Passwort zurücksetzen</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleLogout}>Ausloggen</Dropdown.Item>
                </Dropdown>
                <Navbar.Toggle />
            </div>
            </div>
          </div>
          
        </div>
        
      </Navbar>
      <div className="w-full border-b-2 border-gray-200 dark:border-gray-700"></div>
    </div>
  );
};

export default KPINavbar;

import type { FC } from "react";
import React from "react";
import NpsScale from "./nps_scale.tsx";


// KPI Feedback Component Supervisor Neu


// Infos zur Darstellung 
interface FeedbackData {
    email_supervisor: string;
    email_user: string;
    firstname_user: string; 
    lastname_user: string; 
    kpi_date: string; 
    kpi_feedback_id: string;
    kpi_text: string;
    kpi_time: string; 
    kpi_value: number; 
    custom1: string; 
    custom2: string;
    custom3: string;
    custom4: string;
    custom5: string;
    custom6: string;
    custom7: string;
    custom8: string;
    custom9: string;
    custom10: string;
    custom11: string;
    custom12: string;
    custom13: string;
    custom14: string;
    custom15: string;
    custom16: string;
    custom17: string;
    custom18: string;
    custom19: string;
    custom20: string;
    custom21: string;
    custom22: string;
    custom23: string;
    custom24: string;
    custom25: string;
    custom26: string;
    custom27: string;
    custom28: string;
    custom29: string;
    custom30: string;
    custom31: string;
    custom32: string;
    custom33: string;
    custom34: string;
    custom35: string;
    custom36: string;
    custom37: string;
    custom38: string;
    custom39: string;
    custom40: string;
    mandant: string;
    survey1: string;
    survey2: string;
    survey3: string;
    survey4: string;
    survey5: string;
    survey6: string;
    survey7: string;
    survey8: string;
    survey9: string;
    survey10: string;
    survey11: string;
    survey12: string;
    survey13: string;
    survey14: string;
    survey15: string;
    survey16: string;
    survey17: string;
    survey18: string;
    survey19: string;
    survey20: string;
    survey21: string;
    survey22: string;
    survey23: string;
    survey24: string;
    survey25: string;
    survey26: string;
    survey27: string;
    survey28: string;
    survey29: string;
    survey30: string;
    survey31: string;
}

interface KPIUserProps {
    feedbackData: FeedbackData;
}

const VKPISupervisorNeu: FC<KPIUserProps> = ({ feedbackData }) => {
    return (
      <div className="relative mb-4 h-full rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6 w-full border-2">
        <div className="flow-root">
          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
            <li>
            <div>
                <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Kundenberater </p>
                <div className="mb-4 flex items-center justify-between">
                    <p className="text-lg font-bold leading-none text-gray-900 dark:text-white">
                        {feedbackData.firstname_user + " " +feedbackData.lastname_user}
                    </p>
                </div>
                <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Gesprächspartner</p>
                <div className="mb-4 flex items-center justify-between">
                    <p className="text-lg font-bold leading-none text-gray-900 dark:text-white">
                        {feedbackData.custom18 + ' ' + feedbackData.custom17}
                    </p>
                </div>
            </div>
            </li>
            <li className="py-3 sm:py-4">
              <div className="items-center">
                <NpsScale score={Number(feedbackData.kpi_value)}/>
              </div>
            </li>
            <li>
            <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Datum / Zeit</p>
            <div className="mb-4 flex items-center justify-between">
                <p className="text-base  leading-none text-gray-900 dark:text-white">
                    {feedbackData.kpi_date + ' - ' + feedbackData.kpi_time}
                </p>
            </div>
            </li>
            <li>
            <p className="text-xl font-bold text-gray-500 dark:text-gray-400 mb-1 mt-1">Interview</p>
            <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Begründung NPS</p>
            <div className="mb-4 flex items-center justify-between">
                <p className="text-base text-gray-900 dark:text-white">
                    {feedbackData.kpi_text}
                </p>
            </div>
            <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Beratungswunsch ?</p>
            <div className="mb-4 flex items-center justify-between">
                <p className="text-base text-gray-900 dark:text-white">
                    {feedbackData.survey4}
                </p>
            </div>

            <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Welches Thema oder Anliegen?</p>
            <div className="mb-4 flex items-center justify-between">
                <p className="text-base text-gray-900 dark:text-white">
                    {feedbackData.survey5}
                </p>
            </div>

            <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Terminwunsch ?</p>
            <div className="mb-4 flex items-center justify-between">
                <p className="text-base text-gray-900 dark:text-white">
                    {feedbackData.survey7}
                </p>
            </div>

            <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Haben Sie einen speziellen Ansprechpartner, <br/> von dem Sie dazu kontaktiert werden möchten?</p>
            <div className="mb-4 flex items-center justify-between">
                <p className="text-base text-gray-900 dark:text-white">
                    {feedbackData.survey6}
                </p>
            </div>
            </li>
            <li>
            <p className="text-xl font-bold text-gray-500 dark:text-gray-400 mb-1 mt-1">Kunde</p>
            <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Nr. Verbundführer</p>
            <div className="mb-4 flex items-center justify-between">
                <p className="text-base leading-none text-gray-900 dark:text-white">
                    {feedbackData.custom10}
                </p>
            </div>
            
            <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Zielpersonen</p>
            <div className="mb-4 items-center justify-between space-y-1">
                <p className="text-base leading-none text-gray-900 dark:text-white">
                    {feedbackData.custom11}
                </p>
            </div>
           
            <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Telefon</p>
            <div className="mb-4 items-center justify-between space-y-1">
                <p className="text-base leading-none text-gray-900 dark:text-white">
                    {feedbackData.custom24}
                </p>
                <p className="text-base leading-none text-gray-900 dark:text-white">
                    {feedbackData.custom25}
                </p>
                <p className="text-base leading-none text-gray-900 dark:text-white">
                    {feedbackData.custom26}
                </p>
            </div>
           
            <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Beratungscenter</p>
            <div className="mb-4 items-center justify-between space-y-1">
                <p className="text-base leading-none text-gray-900 dark:text-white">
                    {feedbackData.custom2} 
                </p>
            </div>
            
            <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">SPK/Segment</p>
            <div className="mb-4 items-center justify-between space-y-1">
                <p className="text-base leading-none text-gray-900 dark:text-white">
                    {feedbackData.custom3} 
                </p>
            </div>
           
            <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">OE</p>
            <div className="mb-4 items-center justify-between space-y-1">
                <p className="text-base leading-none text-gray-900 dark:text-white">
                    {feedbackData.custom4}
                </p>
            </div>

            <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Muuuh! ID</p>
            <div className="mb-4 items-center justify-between space-y-1">
                <p className="text-base leading-none text-gray-900 dark:text-white">
                    {feedbackData.kpi_feedback_id}
                </p>
            </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };


  export default VKPISupervisorNeu;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import NavbarSidebarLayout from '../components/navbar-layout.tsx';
import KPIUserNachkontakt from '../components/kpi_user_nachkontakt.tsx';
import VKPIUserKommentiert from '../components/v_kpi_user_kommentiert.tsx';
import KPISuperuserNachkontakt from '../components/kpi_supervisor_nachkontakt.tsx';
import { jwtDecode } from "jwt-decode";

// Definiere Typen für den decodierten Token und die KPI-Daten
interface DecodedToken {
  username: string;
  role: 'user' | 'supervisor';
}

interface KPIData {
  email_supervisor: string;
  email_user: string;
  firstname_user: string; 
  lastname_user: string; 
  kpi_date: string; 
  kpi_feedback_id: string;
  kpi_text: string;
  kpi_time: string; 
  kpi_value: number; 
  custom1: string; 
  custom2: string;
  custom3: string;
  custom4: string;
  custom5: string;
  custom6: string;
  custom7: string;
  custom8: string;
  custom9: string;
  custom10: string;
  custom11: string;
  custom12: string;
  custom13: string;
  custom14: string;
  custom15: string;
  custom16: string;
  custom17: string;
  custom18: string;
  custom19: string;
  custom20: string;
  custom21: string;
  custom22: string;
  custom23: string;
  custom24: string;
  custom25: string;
  custom26: string;
  custom27: string;
  custom28: string;
  custom29: string;
  custom30: string;
  custom31: string;
  custom32: string;
  custom33: string;
  custom34: string;
  custom35: string;
  custom36: string;
  custom37: string;
  custom38: string;
  custom39: string;
  custom40: string;
  mandant: string;
  survey1: string;
  survey2: string;
  survey3: string;
  survey4: string;
  survey5: string;
  survey6: string;
  survey7: string;
  survey8: string;
  survey9: string;
  survey10: string;
  survey11: string;
  survey12: string;
  survey13: string;
  survey14: string;
  survey15: string;
  survey16: string;
  survey17: string;
  survey18: string;
  survey19: string;
  survey20: string;
  survey21: string;
  survey22: string;
  survey23: string;
  survey24: string;
  survey25: string;
  survey26: string;
  survey27: string;
  survey28: string;
  survey29: string;
  survey30: string;
  survey31: string;
  v_kpi_label1: string;
  v_kpi_type1: string;
  v_kpi_value1: string;
  v_kpi_label2: string;
  v_kpi_type2: string;
  v_kpi_value2: string;
  v_kpi_label3: string;
  v_kpi_type3: string;
  v_kpi_value3: string;
  v_kpi_label4: string;
  v_kpi_type4: string;
  v_kpi_value4: string;
  v_kpi_label5: string;
  v_kpi_type5: string;
  v_kpi_value5: number;
  v_kpi_label6: string;
  v_kpi_type6: string;
  v_kpi_value6: string;
  v_kpi_desc_label_1: string;
  v_kpi_desc_val_1: string;
  v_kpi_desc_label_2: string;
  v_kpi_desc_val_2: string;
  v_kpi_desc_label_3: string;
  v_kpi_desc_val_3: string;
  v_kpi_desc_label_4: string;
  v_kpi_desc_val_4: string;
  v_kpi_desc_label_5: string;
  v_kpi_desc_val_5: string;
  v_kpi_desc_label_6: string;
  v_kpi_desc_val_6: string;
  v_kpi_desc_label_7: string;
  v_kpi_desc_val_7: string;
  v_kpi_desc_label_8: string;
  v_kpi_desc_val_8: string;
  v_kpi_desc_label_9: string;
  v_kpi_desc_val_9: string;
  v_kpi_desc_label_10: string;
  v_kpi_desc_val_10: string;
  [key: string]: any;
}

const DashboardPageWiedervorlage: FC = () => {
  const [role, setRole] = useState<string>('');
  const [kpiData, setKpiData] = useState<KPIData[]>([]);

  useEffect(() => {
    // Decodiere den JWT Token
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token) as DecodedToken;
      setRole(decoded.role);
      // Get Request an die API
      if(role === 'user'){
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getkpi/remind_kpi?email_user=${decoded.username}`, {
          headers: {
            'authorization': 'Bearer ' + token
          }
        })
        .then(response => response.json())
        .then(data => {
          setKpiData(data);
        })
        .catch(error => console.error('Fehler beim Abrufen der KPI-Daten:', error));
      } else if(role === 'supervisor'){
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getkpisupervisor/remind_kpi?email_supervisor=${decoded.username}`, {
          headers: {
            'authorization': 'Bearer ' + token
          }
        })
        .then(response => response.json())
        .then(data => {
          setKpiData(data);
        })
        .catch(error => console.error('Fehler beim Abrufen der KPI-Daten:', error));
      }
    }
  }, [role]);

  return (
    <NavbarSidebarLayout>
      <div className="px-4 pt-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5">
          {role === 'user' && Array.isArray(kpiData) && kpiData?.map((kpi, index) => (
             <>
              {kpi.version === 'incident' && <KPIUserNachkontakt key={index} feedbackData={kpi} archiv={false} /> }
              {kpi.version === 'kpi' &&  <VKPIUserKommentiert key={index} feedbackData={kpi} archiv={false} /> }
             </> 
          ))}
          {role === 'supervisor' && Array.isArray(kpiData) && kpiData?.map((kpi, index) => (
            <KPISuperuserNachkontakt key={index} feedbackData={kpi} archiv={false} />
          ))}
        </div>
      </div>
    </NavbarSidebarLayout>
  );
};

export default DashboardPageWiedervorlage;

import React from 'react';
import 'flowbite';

interface AdditionalKPI {
  title: string;
  value: string;
}

interface KPIComponentProps {
  title: string;
  value: string;
  difference: number;
  subtitle: string;
  additionalKPIs?: AdditionalKPI[]; // Optional, kann bis zu vier zusätzliche KPIs enthalten
}

const KPIComponent: React.FC<KPIComponentProps> = ({ title, value, difference, subtitle, additionalKPIs }) => {
  //const badgeColor = difference >= 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800';
  const badgeColor = difference >= 0 ? 'bg-red-100 text-red-800' : 'bg-red-100 text-red-800';

  return (
    <div className="p-4 bg-white rounded-lg border shadow-md">
      <div className="mb-4">
        <h5 className="mb-2 text-md tracking-tight text-gray-900">{title}</h5>
        <p className="mb-2 text-md font-semibold text-gray-900">{value}</p>
        <div className='flex'>
        <span className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium mr-2 ${badgeColor}`}>
          {difference >= 0 ? `+${difference}` : `${difference}`}
          <p className="ml-2">{subtitle}</p>
        </span>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {additionalKPIs?.slice(0, 4).map((kpi, index) => (
          <div key={index} className="flex flex-col items-center p-2 bg-gray-50 rounded-lg border">
            <h6 className="mb-1 text-xs font-semibold tracking-tight text-gray-900">{kpi.title}</h6>
            <p className="text-xs font-medium text-gray-900">{kpi.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KPIComponent;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Label, TextInput } from "flowbite-react";
import type { FC } from "react";
import muuuhlogo from "../assets/muuuh_logo.png";
import React, { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext.tsx'; 

const PWRecoveryPage: FC = function () {
   
  const [email_user, setEmail_user] = useState<string>('');
  const [error, setError] = useState<string>(''); 
  const [successMessage, setSuccessMessage] = useState<string>('');
  const auth = useAuth();
  const navigate = useNavigate();

   // Logo dynamisch je Mandant laden 
   let location = useLocation();
   let pathSegments = location.pathname.split('/')
   let mandant_seg = pathSegments[1]
   let logopath = process.env.PUBLIC_URL +'/'+ mandant_seg + '.svg'

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
  
    event.preventDefault();

    setError(''); 
    setSuccessMessage('');

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/passwordrecovery`;

    try {
      console.log(apiUrl)
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email_user }),
      });

      if (!response.ok) {
        // Wenn der Login fehlschlägt, setze eine entsprechende Fehlermeldung
        setError('Fehler bei der Anforderung. Bitte überprüfe deine Eingaben.');
        return;
      }
      setSuccessMessage('Mail zum Zurücksetzen des Passworts versendet!');
      const data = await response.json();
    } catch (error) {
      console.error('Ein Fehler ist aufgetreten:', error);
      setError('Ein Fehler ist aufgetreten. Bitte versuche es später erneut.');
    }
  };
 
  return (
    <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">
      <div className="flex items-center gap-x-1">
            <img
            alt="Muuuh Group Logo"
            src={muuuhlogo}
            className="w-1/4"
            />
        </div>
      <Card
        className="w-full md:max-w-screen-sm [&>img]:hidden md:[&>img]:w-96 md:[&>img]:p-0 md:[&>*]:w-full md:[&>*]:p-16 lg:[&>img]:block"
      >
        <img
            alt="Logo"
            src={logopath}
            className="w-1/3"
            />
        <h1 className="mb-3 text-2xl font-bold dark:text-white md:text-3xl">
          Passwort vergessen
        </h1>
        <p className="text-sm text-gray-500 dark:text-gray-300">
            Wenn Sie über ein Benutzerkonto verfügen, <br/>
            erhalten Sie via Mail ein neues Passwort.  
        </p>
        <form onSubmit={handleLogin}>
        <div className="mb-4 flex flex-col gap-y-3">
            <Label htmlFor="email">E-Mail</Label>
            <TextInput
              id="email"
              name="email"
              placeholder="name@mail.de"
              type="email"
              value={email_user}
              onChange={(e) => setEmail_user(e.target.value)}
            />
          </div>
          <div className="mb-6">
          <Button 
              type="submit" 
              disabled={!email_user.includes('@')} // Deaktiviert den Button, wenn kein "@" im E-Mail-Feld enthalten ist
              className={`w-full lg:w-auto ${!email_user.includes('@') ? 'bg-gray-500' : 'bg-spkrot hover:bg-gray-500'}`}>
              Passwort zurücksetzten
            </Button>
          </div>
          {successMessage && <p className="text-spkrot">{successMessage}</p>}
        </form>
      </Card>
    </div>
  );
};

export default PWRecoveryPage;
import React, { useState } from 'react';
import { Modal, Button, Textarea, Radio, Label } from 'flowbite-react';

interface ChatModalUserProps {
  feedbackid: string;
  firstname: string; 
  lastname: string;
  email_user: string; 
  sourcetable: string; 
  targettable: string; 
}

const VKPIChatModalUser: React.FC<ChatModalUserProps> = ({ feedbackid, firstname, lastname, email_user, sourcetable, targettable }) => {
  const [comment, setComment] = useState('');
  const [nachkontakt, setNachkontakt] = useState('Ja');

  const handleSave = () => {
    const token = localStorage.getItem('token'); // Token aus dem LocalStorage holen
    if (!token) {
      alert('Token nicht gefunden. Bitte erneut anmelden.');
      return;
    }

    const data = {
      kpi_feedback_id: feedbackid,
      email_user: email_user,
      value: comment,
      comment_type: 'nachkontakt'
    };

    

    if(nachkontakt === 'Ja'){

      fetch(`${process.env.REACT_APP_API_BASE_URL}/postcomments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + token // Token im Header mitgeben
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Netzwerkantwort war nicht ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Erfolg:', data);
      })
      .catch((error) => {
        console.error('Fehler:', error);
        alert('Fehler beim Speichern des Kommentars.');
      });


      fetch(`${process.env.REACT_APP_API_BASE_URL}/movekpifeedback`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + token // Token im Header mitgeben
        },
        body: JSON.stringify(
          {
            sourceTable: sourcetable,
            targetTable: targettable, 
            kpi_feedback_id: feedbackid
          }
        )
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Netzwerkantwort war nicht ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Erfolg:', data);
        window.location.reload();
      })
      .catch((error) => {
        console.error('Fehler:', error);
        alert('Fehler beim Verschieben des Feedbacks');
      });


    } else if(nachkontakt === 'Nein'){

      fetch(`${process.env.REACT_APP_API_BASE_URL}/postcomments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + token // Token im Header mitgeben
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Netzwerkantwort war nicht ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Erfolg:', data);
      })
      .catch((error) => {
        console.error('Fehler:', error);
        alert('Fehler beim Speichern des Kommentars.');
      });


      fetch(`${process.env.REACT_APP_API_BASE_URL}/movekpifeedback`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + token 
        },
        body: JSON.stringify(
          {
            sourceTable: 'new_kpi',
            targetTable: 'edited_kpi', 
            kpi_feedback_id: feedbackid
          }
        )
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Netzwerkantwort war nicht ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Erfolg:', data);
        window.location.reload();
      })
      .catch((error) => {
        console.error('Fehler:', error);
        alert('Fehler beim Verschieben des Feedbacks');
      });

    }
    
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNachkontakt(event.target.value);
  };


  return (
    <>
      <Modal.Header>Kommentar</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
            <>
              <div className="grid grid-flow-col justify-stretch space-x-4">
                <div className="max-w-md">
                  <div className="mb-2 block">
                    <Label htmlFor="comment" value="Wie kam es zu dieser Abweichung?" />
                  </div>
                  <Textarea 
                    id="comment" 
                    placeholder="" 
                    required 
                    rows={4} 
                    value={comment} 
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
              </div>
              <fieldset className="flex max-w-md flex-col gap-4">
                <legend className="mb-4">Wurde eine Korrektur vorgenommen?</legend>
                <div className="flex items-center gap-2">
                  <Radio id="nachkontaktja" name="nachkontakt" value="Ja" onChange={handleRadioChange} />
                  <Label htmlFor="nachkontaktja">Ja</Label>
                </div>
                <div className="flex items-center gap-2">
                  <Radio id="nachkontaktnein" name="nachkontakt" value="Nein" onChange={handleRadioChange} />
                  <Label htmlFor="nachkontaktnein">Nein</Label>
                </div>
              </fieldset>
              <Button className="border-2 dark:text-white" color="gray dark:text-white" onClick={handleSave}>
                Speichern
              </Button>
            </>
        </div>
      </Modal.Body>
    </>
  );
};

export default VKPIChatModalUser;
